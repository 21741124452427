/* src/App.css */

.app {
  display: flex;
  height: 100vh;
  overflow: hidden; /* Prevent scrolling */
}

.left-section {
  flex: 1;
  overflow: hidden; /* Ensure the video doesn't cause scroll */
}

.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f0;
}

.content {
  flex: 1;
  overflow-y: auto; /* Allow vertical scrolling if needed */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.language-selector {
  margin-right: 10px;
}

.language-selector button {
  margin-left: 5px;
}