/* ElectricalEnergyScreen.css */

.chart-container {
  width: 100%;
  height: 400px;
}

.button-selected {
  background-color: #007bff;
  color: #fff;
}

.button-unselected {
  background-color: #e0e0e0;
  color: #000;
}

.container {
  display: flex;
  flex-wrap: wrap; /* Ensure cards wrap to the next row when necessary */
  gap: 20px; /* Add space between cards */
}
