/* src/components/EVChargingScreen.css */

.container {
  flex: 1;
  padding: 20px 0;
  overflow-y: auto;
  background-color: #f5f5f5;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.chart-container {
  width: 95%;
  height: 300px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
}

.detailed-explanation {
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.6;
  color: #444444;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.detailed-explanation strong {
  font-weight: bold;
}

.detailed-explanation em {
  font-style: italic;
}

.battery-status {
  max-width: 300px;
  margin: 0 auto 40px;
  text-align: center;
}

.battery-status h3 {
  margin-bottom: 10px;
  color: #333333;
}

.battery-bar {
  width: 100%;
  height: 25px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.battery-fill {
  height: 100%;
  transition: width 0.5s ease-in-out;
}

.battery-percentage {
  margin-top: 5px;
  font-weight: bold;
  color: #555555;
}

.explanation {
  text-align: center;
  font-style: italic;
  margin: 10px auto 20px;
  max-width: 90%;
  color: #666666;
}
