/* src/components/PeakLoadShavingScreen.css */

.container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.chart-container {
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
}

.table-container {
  width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling on small screens */
  margin-bottom: 20px;
}

.plsh-table {
  width: 100%;
  border-collapse: collapse;
}

.plsh-table th,
.plsh-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.plsh-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.plsh-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.plsh-table tr:hover {
  background-color: #f1f1f1;
}

.detailed-explanation {
  margin-bottom: 20px;
}

.explanation {
  font-size: 14px;
  color: #555;
}

.language-switcher {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.language-switcher button {
  margin-left: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  background-color: #f0f0f0;
  border-radius: 3px;
  transition: background-color 0.3s;
}

.language-switcher button:hover {
  background-color: #ddd;
}