/* src/index.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}

nav a.active {
  font-weight: bold;
  color: #007bff;
  border-bottom: 2px solid #007bff;
}